import { Fragment } from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
/** @jsx jsx */
import { jsx, Grid, Heading, Image, Themed } from "theme-ui"
import Layout from '../components/layout'

export const query = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        name
        companyName
        job
        portrait {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
        address
        phones {
          name
          number
        }
        email
        website
        facebook {
          name
          url
        }
        instagram {
          name
          url
        }
      }
      body
    }
  }
`

function coordinatesLine(key, value) {
  return <Fragment><strong>{key} :</strong> {value}<br/></Fragment>
}

export default function AssociationPage({ data }) {
  const { frontmatter: { name, companyName, job, portrait, address, phones, email, website, facebook, instagram }, body } = data.mdx
  const image = getImage(portrait)

  return (
    <Layout
      seo={{
        title: `${name} - ` + (companyName ? `${companyName} - ` : '') + `${job} - Monnières (44) - Swing, collectif d'audacieuses`,
        description: `${name} est ${job} à Monnières en Loire-Atlantique (44). En tant que femmes entrepreneuses à Monnières, elle fait partie du collectif Swing.`,
        image: image && image.images.fallback.src
      }}
      containerClassName="vcard"
    >
      <Heading as="h1" className="fn" sx={{ fontSize: 7 }}>{name}</Heading>
      <div sx={{ color: 'secondary', fontSize: 4, mb: 4 }}>
        {companyName && (<Fragment><span className="org">{companyName}</span> - </Fragment>)}
        <span className="role">{job}</span>
      </div>

      <Grid gap={4} columns={[1, '1fr 1fr']}>
        <div>
          <Image sx={{ display: 'block' }}
            width={image.width} height={image.height}
            src={image.images.fallback.src} srcSet={image.images.fallback.srcSet} sizes={image.images.fallback.sizes}
            alt=""
          />
        </div>
        <div>
          <address sx={{ lineHeight: 1.6, fontStyle: "normal" }}>
            <Themed.h3>Coordonnées</Themed.h3>
            {address && coordinatesLine('Adresse', address)}
            {phones?.map(phone => coordinatesLine(phone.name, (<Themed.a href={`tel:${phone.number}`} className="tel">{phone.number}</Themed.a>)))}
            {email && coordinatesLine('Email', (<Themed.a href={`mailto:${email}`} className="email">{email}</Themed.a>))}
            {website && coordinatesLine('Site internet', (<Themed.a href={website} className="url">{website}</Themed.a>))}
            {facebook && coordinatesLine('Facebook', (<Themed.a href={facebook.url}>{facebook.name}</Themed.a>))}
            {instagram && coordinatesLine('Instagram', (<Themed.a href={instagram.url}>{instagram.name}</Themed.a>))}
          </address>
          <div>
            <MDXRenderer>{body}</MDXRenderer>
          </div>
        </div>
      </Grid>
    </Layout>
  )
}
